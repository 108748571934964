import { CompobotTelemetryType } from 'types/compobots.type';

const reduceToDaily = ({ Data }: { Data: CompobotTelemetryType[] }) => {
  const result: { [key: string]: CompobotTelemetryType } = {};
  Data.forEach((item) => {
    const date = new Date(item.createdAt).toISOString().split('T')[0];
    result[date] = item;
  });
  return Object.values(result);
};

export const createLabel = ({
  Data,
  index,
  values,
}: {
  Data: CompobotTelemetryType[];
  index: number;
  values: any;
}) => {
  const filteredLabels = reduceToDaily({ Data });

  if (filteredLabels.find((item) => item.createdAt === Data[index].createdAt)) {
    const newLabel = new Date(Data[index].createdAt)
      .toLocaleString()
      .substring(0, 13);

    if (!values.find((item: any) => item.label === newLabel)) return newLabel;
  }

  return '';
};
