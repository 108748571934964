import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { lowerCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import BarChart from '../../components/Chart/BarChart';
import LineChart from '../../components/Chart/LineChart';
import {
  getCompobotKernelLogs,
  getCompobotTelemetry,
  getCompobots,
} from '../../services/compobot.service';
import {
  CompobotKernelLogType,
  CompobotTelemetryType,
  CompobotType,
} from '../../types/compobots.type';
//import TileNum from '../../components/Tile/TileNum';
import TileStr from '../../components/Tile/TileStr';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export default function CompoBotTelemetryPage() {
  // const [fvNev, fvSettere] = updateloReactFv<tipusa>(tipusAmitFelulirAKonkretTipusa)
  const { t } = useTranslation();
  const today = new Date();
  const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  const startFromDate = sevenDaysAgo.toISOString().split('T')[0];
  const startToDate = today.toISOString().split('T')[0];
  const [telemetryData, setTelemetryData] = useState<CompobotTelemetryType[]>(
    [],
  );
  const [logs, setLogs] = useState<CompobotKernelLogType[]>([]);
  const [compobots, setCompobots] = useState<CompobotType[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [fromDate, setFromDate] = useState(startFromDate);
  const [toDate, setToDate] = useState(startToDate);
  const [logLevel, setLogLevel] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getCompobots().then((r) => setCompobots(r.data));
    const searchParams = new URLSearchParams(location.search);
    const deviceIdParam = searchParams.get('deviceId') || '';
    const fromDateParam = searchParams.get('fromDate') || fromDate;
    const toDateParam = searchParams.get('toDate') || toDate;
    const logLevelParam = searchParams.get('logLevel') || '';

    setSelectedDeviceId(deviceIdParam);

    fromDateParam.length > 0 && setFromDate(fromDateParam);
    toDateParam.length > 0 && setToDate(toDateParam);

    setLogLevel(logLevelParam);
    handleFetchTelemetry(deviceIdParam, fromDateParam, toDateParam);
    handleFetchLogs(deviceIdParam, fromDateParam, logLevelParam);
  }, [location.search]);

  const handleFetchTelemetry = async (
    deviceId?: string,
    fromDate?: string,
    toDate?: string,
  ) => {
    try {
      const fetchedTelemetry = await getCompobotTelemetry(
        deviceId,
        fromDate,
        toDate,
      );
      setTelemetryData(fetchedTelemetry.data);
    } catch (error) {
      console.error('Error fetching telemetry: ', error);
    }
  };

  const handleFetchLogs = async (
    deviceId?: string,
    fromDate?: string,
    logLevel?: string,
  ) => {
    try {
      const fetchedLogs = await getCompobotKernelLogs(
        deviceId,
        fromDate,
        logLevel,
      );
      setLogs(fetchedLogs.data);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const updateSearchParams = (params: { [key: string]: string }) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      value ? searchParams.set(key, value) : searchParams.delete(key);
    });
    navigate({ search: searchParams.toString() });
  };

  const clearFilters = () => {
    setSelectedDeviceId('');
    setFromDate('');
    setToDate('');
    setLogLevel('');
    updateSearchParams({
      deviceId: '',
      fromDate: '',
      toDate: '',
      logLevel: '',
    });
    handleFetchTelemetry();
    handleFetchLogs();
  };

  //------tile and chart datas---------
  // TODO: status and lastLog getting dynamicly

  //status: if there was any kind of telemetry or kernel log in the last half an hour it should be online
  const stat = () => {
    const currentTime = new Date();
    const testLogs: CompobotKernelLogType[] = [];
    const lastLog = new Date(testLogs.map((item) => item.createdAt)[0]);
    const lastTelemetry = new Date(
      telemetryData.map((item) => item.createdAt)[0],
    );
    let lastData = lastLog;

    if (lastLog.getDate() < lastTelemetry.getDate()) lastData = lastTelemetry;

    console.info('The last log: ', lastLog.toDateString());
    console.info('The last telemetry: ', lastTelemetry.toDateString());
    const halfHourInMillis = 30 * 60 * 1000;
    const timeDifference = currentTime.getTime() - lastData.getTime();

    if (timeDifference > 0 && timeDifference <= halfHourInMillis) {
      return 'Online';
    } else {
      return 'Offline';
    }
  };

  const fixing = (toFix: number, howPrec: number) => {
    if (!toFix) toFix = 0;
    return toFix.toFixed(howPrec);
  };

  const tileLogData = (type_: string) => {
    const filteredLogs = logs
      .filter((item) => item.key === type_)
      .sort((one, other) => (one.createdAt > other.createdAt ? 1 : -1))
      .map((item) => item.value);

    return filteredLogs;
  };

  const tileTelemetryData = (type_: string) => {
    const data = telemetryData
      .filter((item) => item.key === type_)
      .sort((one, other) => (one.createdAt < other.createdAt ? 1 : -1))
      .map((item) => item.value);

    return data;
  };

  const lastTelemetryDate = () => {
    const date = telemetryData
      .sort((one, other) => (one.createdAt < other.createdAt ? 1 : -1))
      .map((item) => item.createdAt)[0];

    const lastUpdatedAt = date
      ? date.substring(0, 10) + ' ' + date.substring(11, 19)
      : '-';

    return lastUpdatedAt;
  };

  const chartTelemetryData = (type_: string) => {
    return telemetryData
      .filter((item) => lowerCase(item.key) === lowerCase(type_))
      .sort((one, other) => (one.createdAt > other.createdAt ? 1 : -1));
  };

  //---------UI return elements-----------

  return (
    <Container>
      <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          component="a"
          onClick={() => navigate('/compobots')}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {t('compobotTelemetryPage.compobotList')}
        </Typography>
        <Typography variant="h4" component="span">
          {'>'}
        </Typography>
        <Typography variant="h4" component="span">
          {t('compobotTelemetryPage.telemetry')}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="compobot-select-label">
            {t('compobotTelemetryPage.deviceId')}
          </InputLabel>
          <Select
            labelId="compobot-select-label"
            value={selectedDeviceId || 'all'}
            label={t('compobotTelemetryPage.deviceId')}
            onChange={(e) => {
              const value = e.target.value === 'all' ? '' : e.target.value;
              setSelectedDeviceId(value);
              updateSearchParams({ deviceId: value });
              handleFetchTelemetry(value, fromDate, toDate);
              handleFetchLogs(value, fromDate, logLevel);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            {compobots.map((compobot) => (
              <MenuItem key={compobot.deviceId} value={compobot.deviceId}>
                {compobot.organisation?.companyName} - {compobot.deviceId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={t('compobotTelemetryPage.fromDate')}
          type="date"
          value={fromDate}
          onChange={(e) => {
            setFromDate(e.target.value);
            updateSearchParams({ fromDate: e.target.value });
            handleFetchTelemetry(selectedDeviceId, e.target.value, toDate);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            max: toDate,
          }}
        />
        <TextField
          label={t('To Date')}
          type="date"
          value={toDate}
          onChange={(e) => {
            setFromDate(e.target.value);
            updateSearchParams({ toDate: e.target.value });
            handleFetchTelemetry(selectedDeviceId, e.target.value, fromDate);
          }}
          inputProps={{
            min: fromDate,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button variant="contained" onClick={clearFilters}>
          {t('compobotTelemetryPage.clearFilters')}
        </Button>
      </Stack>

      <Paper sx={{ p: 6, mb: 1 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            gap: 2,
            mb: 10,
          }}
        >
          <TileStr
            label={'Last refresh'}
            value={lastTelemetryDate().toString()}
          />
          <TileStr label={'Status'} value={stat().toString()} />
          <TileStr
            label={'Openings'}
            value={fixing(tileTelemetryData('dooropeningcount')[0], 0)}
          />
          <TileStr
            label={'CPU [°C]'}
            value={fixing(tileLogData('CPU temperature')[0], 1)}
          />
          <TileStr
            label={'CPU [%]'}
            value={fixing(tileLogData('CPU usage')[0], 1)}
          />
          <TileStr
            label={'Memory [%]'}
            value={fixing(tileLogData('Memory usage')[0], 1)}
          />

          <TileStr
            label={'Total weight [g]'}
            value={fixing(tileTelemetryData('weight')[0], 1)}
          />
          <TileStr
            label={'Last weight [g]'}
            value={fixing(tileTelemetryData('deltaweight')[0], 1)}
          />
          <TileStr
            label={'Last Point'}
            value={fixing(tileTelemetryData('point')[0], 0)}
          />
          <TileStr
            label={'Ping [ms]'}
            value={fixing(tileLogData('Ping speed')[0], 1)}
          />
          <TileStr
            label={'Up speed [Mb/s]'}
            value={fixing(tileLogData('Internet upload speed')[0], 1)}
          />
          <TileStr
            label={'Down speed [Mb/s]'}
            value={fixing(tileLogData('Internet download speed')[0], 1)}
          />
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: 5,
            mb: 10,
          }}
        >
          <Box
            style={{
              display: 'flex',
            }}
          >
            <Box width="50%">
              <LineChart
                Data={chartTelemetryData('temperature')}
                Title={'Temperature'}
                border_color={'rgb(255, 99, 132)'}
                background_color={'rgba(255, 99, 132, 0.5)'}
              />
            </Box>
            <Box width="50%">
              <LineChart
                Data={chartTelemetryData('humidity')}
                Title={'Humidity'}
                border_color={'rgb(0,74,147)'}
                background_color={'rgb(100,173,246)'}
              />
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
            }}
          >
            <Box width="50%">
              <LineChart
                Data={chartTelemetryData('weight')}
                Title={'Weight'}
                border_color={'rgb(132, 99, 255)'}
                background_color={'rgba(132, 99, 255, 0.5)'}
              />
            </Box>
            <Box width="50%">
              <BarChart
                Data={chartTelemetryData('deltaweight')}
                Title={'Delta Weight'}
                border_color={'rgb(255, 85, 0)'}
                background_color={'rgba(255, 85, 0, 0.5)'}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
